<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Statistics</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCreditCardCheckOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Checkouts</p>
              <span class="text--primary text-xl font-weight-bold">{{ checkoutCount }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="info" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">HealthCoin</p>
              <span class="text--primary text-xl font-weight-bold">{{ healthCoinCount }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCreditCardCheckOutline, mdiCurrencyUsd, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'

export default {
  props: {
    checkoutCount: {
      type: Number,
      default: 0,
    },
    healthCoinCount: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiCreditCardCheckOutline,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
