var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-view"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "4"
    }
  }, [_vm.user ? _c('bio-panel', {
    attrs: {
      "user": _vm.user
    },
    on: {
      "openEditPhotoAside": _vm.setAside
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "8"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.user ? _c('statistics', {
    attrs: {
      "checkout-count": _vm.user.checkouts_count,
      "health-coin-count": _vm.user.reward_points_count
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "user-tabs",
    attrs: {
      "show-arrows": ""
    },
    model: {
      value: _vm.userTab,
      callback: function callback($$v) {
        _vm.userTab = $$v;
      },
      expression: "userTab"
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.icon
    }, [_c('v-icon', {
      staticClass: "me-3",
      attrs: {
        "size": "20"
      }
    }, [_vm._v(" " + _vm._s(tab.icon) + " ")]), _c('span', [_vm._v(_vm._s(tab.title))])], 1);
  }), 1), _c('v-tabs-items', {
    staticClass: "mt-5 pa-1",
    attrs: {
      "id": "user-tabs-content"
    },
    model: {
      value: _vm.userTab,
      callback: function callback($$v) {
        _vm.userTab = $$v;
      },
      expression: "userTab"
    }
  }, [_c('v-tab-item', [_vm.user ? _c('checkout', {
    attrs: {
      "user-id": _vm.user.id
    }
  }) : _vm._e()], 1), _c('v-tab-item', [_vm.user ? _c('reward-point', {
    attrs: {
      "user-id": _vm.user.id
    }
  }) : _vm._e()], 1), _c('v-tab-item', [_vm.user ? _c('employee', {
    attrs: {
      "user": _vm.user
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _vm.aside ? _c('avatar-aside', {
    attrs: {
      "id": _vm.user.id
    },
    on: {
      "changed": function changed($event) {
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }