import { useNotifyErrors } from '@/composables'
import { fetchUserUsage, getUser } from '@api/user'
import { ref } from '@vue/composition-api'

export default function useList() {
  const user = ref(null)

  const loadUser = id =>
    getUser(id)
      .then(({ data }) => {
        user.value = data.data
      })
      .catch(useNotifyErrors)

  const userUsage = ref(null)

  const loadUserUsage = id =>
    fetchUserUsage(id)
      .then(({ data }) => {
        userUsage.value = data.data
      })
      .catch(useNotifyErrors)

  return {
    user,
    loadUser,
    userUsage,
    loadUserUsage,
  }
}
