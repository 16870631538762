<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
      <div style="padding: 1rem;">
      <h1>Add or Update User avatar</h1>
      <p>A User avatar is required as a squared image.</p>
    </div>
    <avatar-form
      :id="id"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { updateUserPhoto } from '@api/user'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AvatarForm from './AvatarForm.vue'

export default {
  components: { AvatarForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [Number],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = new FormData()
      data.append('file', form.file)
      data.append('_method', 'PUT')
      const request = updateUserPhoto(props.id, data)
      await request
        .then(res => {
          emit('created', res.data.data)
          // emit('changed', res.data.data)
          // boilerplateCardLayout(res.data.data.id)
          location.reload()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
