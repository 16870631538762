<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <bio-panel
          v-if="user"
          :user="user"
          @openEditPhotoAside="setAside"
        ></bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-row>
          <v-col cols="12">
            <statistics
              v-if="user"
              :checkout-count="user.checkouts_count"
              :health-coin-count="user.reward_points_count"
            ></statistics>
          </v-col>

          <v-col cols="12">
            <v-tabs
              v-model="userTab"
              show-arrows
              class="user-tabs"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.icon"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items
              id="user-tabs-content"
              v-model="userTab"
              class="mt-5 pa-1"
            >
              <v-tab-item>
                <checkout
                  v-if="user"
                  :user-id="user.id"
                />
              </v-tab-item>

              <v-tab-item>
                <reward-point
                  v-if="user"
                  :user-id="user.id"
                />
              </v-tab-item>

              <v-tab-item>
                <employee
                  v-if="user"
                  :user="user"
                />
              </v-tab-item>

              <!-- <v-tab-item>
                <usage :userId="userUsage" v-if="userUsage" />
              </v-tab-item> -->
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <avatar-aside
      v-if="aside"
      :id="user.id"
      v-model="aside"
      @changed="aside = false;"
    ></avatar-aside>
  </div>
</template>

<script>
import emitter from '@/eventBus'
import router from '@/router'

import {
defineAsyncComponent, onMounted, onUnmounted, ref,
} from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiCreditCardCheckOutline, mdiCurrencyUsd, mdiInformation } from '@mdi/js'
import AvatarAside from '../components/overview/AvatarAside.vue'
import Statistics from '../components/overview/Statistics.vue'
import useUserView from '../composables/useUserView'

export default {
  components: {
    BioPanel: defineAsyncComponent(() => import('../components/overview/BioPanel.vue')),
    Statistics,
    Checkout: defineAsyncComponent(() => import('../components/overview/Checkout.vue')),
    RewardPoint: defineAsyncComponent(() => import('../components/overview/reward_points/RewardPoint.vue')),
    Employee: defineAsyncComponent(() => import('../components/overview/Employee.vue')),
    AvatarAside,

    // Usage: defineAsyncComponent(() => import('../components/overview/Usage.vue')),
  },
  setup() {
    const userTab = ref(null)

    const {
      user, loadUser, userUsage, loadUserUsage,
    } = useUserView()

    const tabs = [
      { icon: mdiCreditCardCheckOutline, title: 'Checkouts' },
      { icon: mdiCurrencyUsd, title: 'HealthCoin' },
      { icon: mdiInformation, title: 'Employee' },

      // { icon: mdiChartDonut, title: 'Usage' },
    ]

    onMounted(async () => {
      loadUser(router.currentRoute.params.id)

      // loadUserUsage(router.currentRoute.params.id)

      emitter.on('reward-points:updated', e => {
        loadUser(router.currentRoute.params.id)
      })
    })

    onUnmounted(() => {})

    const aside = ref(false)
    const setAside = () => {
      aside.value = true
    }

    return {
      tabs,
      userTab,
      user,
      loadUser,
      setAside,
      aside,
      userUsage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
